<template>
  <asom-slide-menu
    v-model="showMenu"
    :title="get(selectedRoster, 'officer', '-')"
  >
    <section v-if="selectedRoster" class="space-y-5 mb-16">
      <article class="flex items-center justify-between">
        <aside>
          <h3 class="font-bold text-base text-gray-900">
            {{ get(selectedRoster, "station.stationName", "-") }}
          </h3>
          <asom-tooltip>
            <template #toggleText>
              <asom-badge
                variant="light"
                class="flex items-center space-x-1 p-1"
              >
                <asom-icon icon="performance" size="sm" />
                <span
                  >{{ get(selectedRoster, "dwsText", "-") }} ({{
                    get(selectedRoster, "dws", "-")
                  }})</span
                >
              </asom-badge>
            </template>
            DWS
          </asom-tooltip>
        </aside>
        <aside>
          <p class="text-base text-gray-900 flex flex-col">
            <asom-tooltip>
              <template #toggleText>
                <span class="flex items-center space-x-1">
                  <asom-icon icon="time" size="sm" />
                  <span>{{
                      displayUtcDate2(get(selectedRoster, "planDate", ""))
                    }}</span>
                </span>
              </template>
              Date
            </asom-tooltip>
            <asom-tooltip v-if="totalHours || showCoveringStation">
              <template #toggleText>
                <span class="flex items-center space-x-1" v-if="totalHours">
                  <asom-icon icon="sand-clock" size="sm" />
                  <span>{{ totalHours }}</span>
                </span>
              </template>
              Duration
            </asom-tooltip>
          </p>
        </aside>
      </article>
      <!-- ROSTER TIMELINE BREAKDOWN-->
      <asom-divider v-if="totalHours" text="Roster Details" />
      <dl class="space-y-8 sm:space-y-0" v-if="totalHours">
        <div class="sm:flex sm:py-3" v-if="selectedRosterDuration">
          <dt
            class="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48"
          >
            <span class="flex items-center space-x-1">
              <asom-icon icon="timelapse" size="sm" />
              <span>Non-OT</span>
            </span>
          </dt>
          <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:ml-6 sm:col-span-2">
            {{selectedRosterDuration}}
          </dd>
        </div>
        <div class="sm:flex sm:py-3" v-if="selectedRoster && selectedRoster.otHours">
          <dt
            class="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48"
          >
            <span class="flex items-center space-x-1">
              <asom-icon icon="timelapse" size="sm" />
              <span>OT</span>
            </span>
            <span class="text-xs font-normal "
              >* OT hours takes 1 minute to update</span
            >
          </dt>
          <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:ml-6 sm:col-span-2">
            {{ get(selectedRoster, "otHours", "-") }}
            <span class="italic">(hh:mm)</span>
          </dd>
        </div>
        <div class="sm:flex sm:py-3" v-if="hasOtDurations">
          <dt
            class="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48"
          >
            <span class="flex items-center space-x-1  ml-4">
              <span>OT Sessions</span>
            </span>
          </dt>
          <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:ml-6 sm:col-span-2">
            <p v-for="otDuration in otDurations" :key="otDuration.otDurationId">
              {{ formatOtDuration(otDuration) }}
            </p>
          </dd>
        </div>
        <div class="sm:flex sm:py-3" v-if="hasOtDurations">
          <dt
            class="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48"
          >
            <span class="flex items-center space-x-1  ml-4">
              <span>OT Break Duration</span>
            </span>
          </dt>
          <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:ml-6 sm:col-span-2">
            {{ formatBreakDuration(selectedRoster.otBreakHours) }}
          </dd>
        </div>
        <div class="sm:flex sm:py-3" v-if="hasOtDurations">
          <dt
            class="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48"
          >
            <span class="flex items-center space-x-1  ml-4">
              <span>Remarks</span>
            </span>
          </dt>
          <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:ml-6 sm:col-span-2">
            {{ get(otDurations, '0.remark', 'NIL') }}
          </dd>
        </div>
      </dl>
      <hr v-if="selectedRosterDuration" />
      <!-- END ROSTER TIMELINE BREAKDOWN-->

      <dl class="sm:space-y-0" v-if="showCoveringStation">
        <div class="sm:flex">
          <dt
            class="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48"
          >
            <span class="flex items-center space-x-1 italic">
              <span>This has a covering shift</span>
            </span>
          </dt>
        </div>

        <div class="sm:flex sm:py-3">
          <dt
            class="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48"
          >
            <span class="flex items-center space-x-1">
              <asom-icon icon="ui-kit" size="sm" />
              <span>Covering Station</span>
            </span>
          </dt>
          <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:ml-6 sm:col-span-2">
            {{ get(selectedRoster, "onChangeCoveringStation") }}
          </dd>
        </div>
      </dl>
      <dl class="sm:space-y-0" v-if="showBaseStation">
        <div class="sm:flex">
          <dt
            class="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48"
          >
            <span class="flex items-center space-x-1 italic">
              <span>This is a covering shift</span>
            </span>
          </dt>
        </div>

        <div class="sm:flex sm:py-3">
          <dt
            class="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48"
          >
            <span class="flex items-center space-x-1">
              <asom-icon icon="ui-kit" size="sm" />
              <span>Base Station</span>
            </span>
          </dt>
          <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:ml-6 sm:col-span-2">
            {{ get(selectedRoster, "onChangeBaseStation") }}
          </dd>
        </div>
      </dl>
      <hr v-if="showBaseStation" />
      <dl class="space-y-8 sm:space-y-0">
        <div class="sm:flex sm:py-3">
          <dt
            class="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48"
          >
            <span class="flex items-center space-x-1">
              <asom-icon icon="calendar-two" size="sm" />
              <span>
                No. of days worked
                <br/>
                in a row
              </span>
            </span>
          </dt>
          <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:ml-6 sm:col-span-2">
            {{ get(selectedRoster, "daysInRow", "-") }}
          </dd>
        </div>

        <div class="sm:flex sm:py-3">
          <dt
            class="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48"
          >
            <span class="flex items-center space-x-1">
              <asom-icon icon="timelapse" size="sm" />
              <span>Monthly OT Hours</span>
            </span>
            <span class="text-xs font-normal "
              >* OT hours takes 1 minute to update</span
            >
          </dt>
          <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:ml-6 sm:col-span-2">
            {{ get(selectedRoster, "workedOtHours", "-") }}
            <span class="italic">(hh:mm)</span>
          </dd>
        </div>
      </dl>

      <article v-if="hasPreviousDws">
        <asom-divider text="Previous DWS" />
        <dl class="space-y-8 sm:space-y-0">
          <div class="sm:flex sm:py-3">
            <dt
              class="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48"
            >
              <span class="flex items-center space-x-1">
                <asom-icon icon="performance" size="sm" />
                <span>DWS</span>
              </span>
            </dt>
            <dd
              class="mt-1 text-sm text-gray-900 sm:mt-0 sm:ml-6 sm:col-span-2"
            >
              {{ get(selectedRoster, "previousDws", "-") }}
            </dd>
          </div>
          <div class="sm:flex sm:py-3">
            <dt
              class="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48"
            >
              <span class="flex items-center space-x-1">
                <asom-icon icon="time" size="sm" />
                <span>Date</span>
              </span>
            </dt>
            <dd
              class="mt-1 text-sm text-gray-900 sm:mt-0 sm:ml-6 sm:col-span-2"
            >
              {{ displayUtcDate(get(selectedRoster, "previousDate")) }}
            </dd>
          </div>
          <div class="sm:flex sm:py-3">
            <dt
              class="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48"
            >
              <span class="flex items-center space-x-1">
                <asom-icon icon="user" size="sm" />
                <span>Supervisor Id</span>
              </span>
            </dt>
            <dd
              class="mt-1 text-sm text-gray-900 sm:mt-0 sm:ml-6 sm:col-span-2"
            >
              {{ get(selectedRoster, "supervisorId") }}
            </dd>
          </div>
          <div class="sm:flex sm:py-3">
            <dt
              class="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48"
            >
              <span class="flex items-center space-x-1">
                <asom-icon icon="info" size="sm" />
                <span>Remarks</span>
              </span>
            </dt>
            <dd
              class="mt-1 text-sm text-gray-900 sm:mt-0 sm:ml-6 sm:col-span-2"
            >
              {{ get(selectedRoster, "remark", "NIL") }}
            </dd>
          </div>
        </dl>
      </article>


      <article v-if="get(selectedRoster, 'additionalShift')">
        <asom-divider text="Officer covers another shift" />
        <dl class="space-y-8 sm:space-y-0">
          <div class="sm:flex sm:py-3">
            <dt
              class="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48"
            >
              <span class="flex items-center space-x-1">
                <asom-icon icon="performance" size="sm" />
                <span>Covering DWS</span>
              </span>
            </dt>
            <dd
              class="mt-1 text-sm text-gray-900 sm:mt-0 sm:ml-6 sm:col-span-2"
            >
              {{ get(selectedRoster, "additionalShift.dwsText", "-") }} ({{
                get(selectedRoster, "additionalShift.dws", "-")
              }})
            </dd>
          </div>
          <div class="sm:flex sm:py-3">
            <dt
              class="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48"
            >
              <span class="flex items-center space-x-1">
                <asom-icon icon="time" size="sm" />
                <span>Duration</span>
              </span>
            </dt>
            <dd
              class="mt-1 text-sm text-gray-900 sm:mt-0 sm:ml-6 sm:col-span-2"
            >
              {{ additionalRosterDuration}}
            </dd>
          </div>
        </dl>
      </article>

      <article v-if="get(selectedRoster, 'isMutualExchange')">
        <asom-divider text="This shift is mutual exchanged" />
        <dl class="space-y-8 sm:space-y-0">
          <div class="sm:flex sm:py-3">
            <dt
              class="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48"
            >
              <span class="flex items-center space-x-1">
                <asom-icon icon="performance" size="sm" />
                <span>Previous Exchanged DWS</span>
              </span>
            </dt>
            <dd
              class="mt-1 text-sm text-gray-900 sm:mt-0 sm:ml-6 sm:col-span-2"
            >
              {{ get(selectedRoster, "exchangedDws", "-") }}
            </dd>
          </div>
          <div class="sm:flex sm:py-3">
            <dt
              class="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48"
            >
              <span class="flex items-center space-x-1">
                <asom-icon icon="time" size="sm" />
                <span>Exchanged Date</span>
              </span>
            </dt>
            <dd
              class="mt-1 text-sm text-gray-900 sm:mt-0 sm:ml-6 sm:col-span-2"
            >
              {{ displayUtcDateTime(get(selectedRoster, "dateExchanged")) }}
            </dd>
          </div>
        </dl>
      </article>

      <article v-if="showCovering && (isActiveShift || isCoveringShift)">
        <asom-divider text="Covering Shift" />
        <asom-alert
          v-if="isCoveringShift"
          title="This is a covering shift"
          variant="info"
        ></asom-alert>
        <section class="relative z-0 mt-2 grid sm:grid-cols-1 md:grid-cols-3 gap-1">
          <asom-button
            v-if="isActiveShift && !isCoveringShift"
            outline
            size="sm"
            text="Add Covering"
            @click="addCovering"
          />
          <asom-button
            v-if="canAssignCovering && isCoveringShift"
            outline
            size="sm"
            text="Cancel Covering Shift"
            @click="toggleCancelCoveringModal(true)"
          />
        </section>
      </article>

      <article v-if="get(selectedRoster, 'isBlockedLeave')">
        <asom-divider text="Indicated Leave" />
        <dl class="space-y-8 sm:space-y-0">
          <div class="sm:flex sm:py-3">
            <dt
              class="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48"
            >
              <span class="flex items-center space-x-1">
                <span>Current Indicated Leave</span>
              </span>
            </dt>
            <dd
              class="mt-1 text-sm text-gray-900 sm:mt-0 sm:ml-6 sm:col-span-2"
            >
              {{ blockedLeaveDuration }}
            </dd>
          </div>
        </dl>
        <section class="relative z-0 mt-2 grid sm:grid-cols-1 md:grid-cols-2 gap-1">
          <asom-button
            v-if="isLoggedInUser"
            outline
            size="sm"
            text="Cancel Indicated Leave"
            variant="error"
            @click="onCancelShiftType('BL')"
          />
        </section>
      </article>
      <template v-if="canTakeActionInCurrentStation || isSelfOffRest ">
        <article v-if="(canMarkUnavailability && canRosterBeModified) || isSelfOffRest" class="space-y-4">
          <asom-divider text="Availability" />
          <section class="relative z-0 mt-2 grid sm:grid-cols-1 md:grid-cols-3 gap-1">
            <template v-if="canTakeActionInCurrentStation">
              <asom-button
                outline
                size="sm"
                v-if="
                  (!isCoveringShift && !checkDWSForAvailability) ||
                    pendingExtensionToCoverInFull
                "
                text="Edit"
                @click="editRoster"
              />
              <asom-button
                outline
                size="sm"
                v-if="isActiveShift && !isCoveringShift && !selectedRoster.otShift"
                text="Mutual Exchange"
                @click="mutualExchange"
              />
              <asom-button
                outline
                size="sm"
                v-if="
                  selectedRoster.pendingReplacement &&
                    !pendingExtensionToCoverInFull
                "
                text="Show Suggestion"
                variant="success"
                @click="replaceStaff"
              />
              <asom-button
                outline
                size="sm"
                v-if="selectedRoster.unavailability && !isCoveringShift"
                text="Mark Unavailable"
                variant="error"
                @click="markUnavailable"
              />
            </template>
            <asom-button
              v-if="!selectedRoster.otVolunteer && isSelfOffRest && !selectedRoster.otUnavailable"
              outline
              text="Volunteer for OT"
              variant="success"
              @click="
                $router.push({
                  name: 'OT_Volunteers',
                  params: { rosterId: selectedRoster.rosterId },
                  query: { date: selectedRoster.planDate },
                })
              "
            />
            <asom-button
              v-if="selectedRoster.otVolunteer && isNonActiveShift"
              outline
              size="sm"
              text="Cancel OT Volunteering"
              variant="error"
              @click="onCancelShiftType('OTV')"
            />
            <asom-button
              outline
              size="sm"
              v-if="!selectedRoster.otUnavailable && !selectedRoster.otVolunteer && isSelfOffRest"
              text="Mark OT Unavailable"
              variant="error"
              @click="markOTUnavailable"
            />
            <asom-button
              outline
              size="sm"
              v-if="selectedRoster.otUnavailable && isLoggedInUser"
              text="Cancel OT Unavailability"
              variant="error"
              @click="onCancelShiftType('OTU')"
            />
            <asom-button
              outline
              size="sm"
              v-if="
                canMarkUnavailability &&
                  checkDWSForAvailability &&
                  !pendingExtensionToCoverInFull
              "
              text="Cancel Unavailability"
              variant="error"
              @click="cancelAvailability"
            />
          </section>
        </article>

        <article class="space-y-4"
          v-if="canTakeActionInCurrentStation && showOTSection"
        >
          <asom-divider text="OT" />
          <section class="relative z-0 mt-2 grid sm:grid-cols-1 md:grid-cols-3 gap-1">
            <extend-vacant
              v-if="showExtend && !selectedRoster.otShift"
              :roster="selectedRoster"
            />
            <asom-button
              outline
              size="sm"
              v-if="showPlanOT && !selectedRoster.otShift"
              text="Plan OT"
              variant="success"
              @click="planOT"
            />
          </section>
        </article>
      </template>
    </section>

    <asom-modal
      v-model="showConfirmCancelShiftChange"
      title="Confirmation"
      :dismissible="false"
    >
      <asom-alert v-if="error" :error-message="error" />
      <p class="pt-4">{{ cancellationShiftType.message }}</p>
      <div class="flex flex-row-reverse pt-4 gap-4">
        <asom-button
          @click="cancelShiftTypeConfirm(cancellationShiftType.type)"
          :disabled="isSubmittingCancelShiftChange"
          :loading="isSubmittingCancelShiftChange"
          text="Confirm"
        />
        <asom-button
          @click="toggleCancelShiftChangeModal(false)"
          text="Cancel"
          variant="secondary"
        />
      </div>
    </asom-modal>
    <asom-modal
      v-model="showConfirmCancelCovering"
      title="Confirmation"
      :dismissible="false"
    >
      <asom-alert v-if="error" :error-message="error" />
      <p class="pt-4">{{ confirmCancelCoveringMessage }}</p>
      <div class="flex flex-row-reverse pt-4 gap-4">
        <asom-button
          @click="cancelCovering"
          :disabled="isSubmittingCancelCovering"
          :loading="isSubmittingCancelCovering"
          text="Confirm"
        />
        <asom-button
          @click="toggleCancelCoveringModal(false)"
          text="Cancel"
          variant="secondary"
        />
      </div>
    </asom-modal>
    <asom-modal
      v-model="cancelUnavailabilityConfirmation"
      title="Confirmation"
      :dismissible="false"
    >
      <asom-alert v-if="error" :error-message="error" />
      <p class="pt-4">Are you sure you would like to Cancel Unavailability</p>
      <div class="flex flex-row-reverse pt-4 gap-4">
        <asom-button
          @click="onConfirmCancelUnavailability"
          :disabled="isSubmittingCancelUnavailability"
          :loading="isSubmittingCancelUnavailability"
          text="Confirm"
        />
        <asom-button
          @click="toggleCancelAvailability(false)"
          text="Cancel"
          variant="secondary"
        />
      </div>
    </asom-modal>
    <asom-modal
      v-model="showAddCoveringModal"
      title="Confirmation"
      :dismissible="false"
    >
      <asom-alert v-if="error" :error-message="error" />
      <p class="pt-4">Please select a Covering Station</p>
      <asom-form-field label="Station" required>
        <asom-input-select
          v-model="formData.station"
          :options="stationOptions"
        />
      </asom-form-field>
      <div class="flex flex-row-reverse pt-4 gap-4">
        <asom-button
          @click="onConfirmAddCovering"
          :disabled="disableAddCoveringConfirmation"
          :loading="isSubmittingAddCovering"
          text="Confirm"
        />
        <asom-button
          @click="toggleAddCovering(false)"
          text="Cancel"
          variant="secondary"
        />
      </div>
    </asom-modal>
  </asom-slide-menu>
</template>
<script>
import get from "lodash.get";
import moment from 'moment';
import { mapGetters } from "vuex";
import {
  cancelCoveringShift,
  removeOTVolunteer,
  removeBlockedLeave,
  removeOTUnavailability,
  cancelAvailability,
  coveringShift,
} from "@/services/manpower.service";
import {
  displayUtcDate,
  displayUtcDate2,
  displayUtcDateTime
} from "@/helpers/dateTimeHelpers";
import { parseDateTime } from "@/helpers/dateTimeHelpers";
import ExtendVacant from "./ExtendVacant";
import { SpareDwsList} from '@/constants/rosterDws';
import ROLES from "../../../constants/APIEnums/roles";

export default {
  props: {
    selectedRoster: {
      type: undefined,
    },
    modelValue: {
      type: Boolean,
    },
  },
  components: {
    ExtendVacant,
  },
  emits: ["update:modelValue"],
  data() {
    return {
      showConfirmCancelCovering: false,
      isSubmittingCancelCovering: false,
      error: null,
      showConfirmCancelShiftChange: false,
      isSubmittingCancelShiftChange: false,
      cancellationShiftType: {
        message: "",
        type: null,
      },
      cancelUnavailabilityConfirmation: false,
      isSubmittingCancelUnavailability: false,
      showAddCoveringModal: false,
      isSubmittingAddCovering: false,
      disableAddCoveringConfirmation: true,
      formData: {
        station: {
          value: null,
          label: null,
        },
      },
    };
  },
  watch: {
    "formData.station": function(newValue) {
      if (newValue) this.disableAddCoveringConfirmation = false;
    },
  },
  computed: {
    ...mapGetters({
      userId: "auth/userId",
      canMarkUnavailability: "auth/canMarkUnavailability",
      canAssignCovering: "auth/canAssignCovering",
      canExtendShift: "auth/canExtendShift",
      canSelfVolunteerForOt: "auth/canSelfVolunteerForOt",
      canPlanOT: "auth/canPlanOT",
      isMSO: "auth/isMSO",
      isSOM: "auth/isSOM",
    }),
    canTakeActionInCurrentStation() {
      const stationId = get(this.selectedRoster, 'station.stationId');
      return this.$store.getters['auth/canTakeActionInCurrentStation'](stationId);
    },
    canRosterBeModified(){
      return this.isMSO || this.isSOM ||
      (get(this.selectedRoster, "baseStation", "") === get(this.selectedRoster, "coveringStation", "") &&
      (get(this.selectedRoster, "onChangeBaseStation", "") === get(this.selectedRoster, "onChangeCoveringStation", "") ||
      !get(this.selectedRoster, "onChangeBaseStation", "")))
    },
    showCovering() {
      return (
        (get(this.selectedRoster, "role") === ROLES.STATION_MANAGER.name ||
        get(this.selectedRoster, "role") === ROLES.SENIOR_STATION_MANAGER.name) &&
        this.canAssignCovering
      );
    },
    stationOptions() {
      return this.$store.getters["smrtResource/stationOptionsByLineId"](
        this.selectedRoster.line.lineId
      );
    },
    showMenu: {
      get() {
        return this.modelValue;
      },
      set(newValue) {
        this.$emit("update:modelValue", newValue);
      },
    },
    isSelfOffRest() {
      const isSelf = this.selectedRoster.officerId === this.userId;
      const isOffRest = ["OFF", "REST"].includes(this.selectedRoster.dws);
      return isSelf && isOffRest;
    },
    isPastRoster() {
      const { planDate } = this.selectedRoster;
      return moment(planDate, "DD/MM/YYYY").isSameOrBefore(new Date(), "day");
    },
    isActiveShift() {
      return get(this.selectedRoster, "isActiveShift", false);
    },
    isNonActiveShift() {
      const isOffRest = ["OFF", "REST"].includes(
        get(this.selectedRoster, "dws", "")
      );
      const today = moment().startOf('day');
      const plan = moment(this.selectedRoster.planDate,'DD/MM/YYYY');
      return isOffRest && plan.isSameOrAfter(today);
    },
    isLoggedInUser() {
      if (this.userId == get(this.selectedRoster, "officerId")) return true;
      else return false;
    },
    pendingExtensionToCoverInFull() {
      if (get(this.selectedRoster, "pendingExtensionToCoverInFull"))
        return true;
      else return false;
    },
    totalHours() {
      return get(this.selectedRoster, 'totalHours', null)
    },
    selectedRosterDuration() {
      if (
        this.selectedRoster &&
        this.selectedRoster.planStart &&
        this.selectedRoster.planEnd &&
        this.selectedRoster.hours
      ) {
        return (
          moment(this.selectedRoster.planStart, "DD/MM/YYYY HH:mm:ss").format(
            "HH:mm"
          ) +
          " - " +
          moment(this.selectedRoster.planEnd, "DD/MM/YYYY HH:mm:ss").format(
            "HH:mm"
          ) +
          " | " +
          this.selectedRoster.hours +
          (this.selectedRoster.hours < 2 ? "hr" : "hrs")
        );
      }
      return null;
    },
    selectedOtDuration() {
      if (
        this.selectedRoster &&
        this.selectedRoster.otStart &&
        this.selectedRoster.otEnd
      ) {
        return (
          moment(this.selectedRoster.otStart, "DD/MM/YYYY HH:mm:ss").format(
            "HH:mm"
          ) +
          " - " +
          moment(this.selectedRoster.otEnd, "DD/MM/YYYY HH:mm:ss").format(
            "HH:mm"
          ) +
          " | " +
          this.selectedRoster.otHours +
          (this.selectedRoster.otHours < 2 ? "hr" : "hrs")
        );
      }
      return null;
    },
    blockedLeaveDuration() {
      if (
        this.selectedRoster &&
        this.selectedRoster.planStart &&
        this.selectedRoster.planEnd &&
        this.selectedRoster.hours
      ) {
        return (
          moment(this.selectedRoster.planStart, "DD/MM/YYYY HH:mm:ss").format(
            "DD/MM/YYYY HH:mm"
          ) +
          " - " +
          moment(this.selectedRoster.planEnd, "DD/MM/YYYY HH:mm:ss").format(
            "DD/MM/YYYY HH:mm"
          )
        );
      }
      return null;
    },
    additionalRosterDuration() {
      const additionalRoster = get(this.selectedRoster, 'additionalShift', {});
      if (
        additionalRoster &&
        additionalRoster.planStart &&
        additionalRoster.planEnd &&
        additionalRoster.hours
      ) {
        return (
          moment(additionalRoster.planStart, "DD/MM/YYYY HH:mm:ss").format(
            "HH:mm"
          ) +
          " - " +
          moment(additionalRoster.planEnd, "DD/MM/YYYY HH:mm:ss").format(
            "HH:mm"
          ) +
          " | " +
          additionalRoster.hours +
          (additionalRoster.hours < 2 ? "hr" : "hrs")
        );
      }
      return null;
    },
    isExtensible() {
      return (
        this.selectedRoster &&
        !this.selectedRoster.pendingReplacement &&
        !this.selectedRoster.hasExtended &&
        !this.selectedRoster.hasCoveringShift &&
        (this.selectedRoster.isWorkableFreeDay || this.selectedRoster.unavailability)
        && !["OFF", "REST"].includes(this.selectedRoster.dws)
        && !SpareDwsList.includes(this.selectedRoster.dws)
      );
    },
    isCoveringShift() {
      return (
        this.selectedRoster &&
        (this.selectedRoster.hasCoveringShift ||
          this.selectedRoster.isCoveringShift)
      );
    },
    confirmCancelCoveringMessage() {
      return `Are you sure you would like to cancel covering shift?`;
    },
    checkDWSForAvailability() {
      const dws = get(this.selectedRoster, "dws");
      return ["MTO", "MUA"].includes(dws);
    },
    hasPreviousDws() {
      return (
        get(this.selectedRoster, "previousDws") &&
        get(this.selectedRoster, "previousDate")
      );
    },
    showPlanOT() {
      return (
        this.canPlanOT &&
        this.selectedRoster &&
        !this.selectedRoster.pendingReplacement &&
        !this.selectedRoster.hasCoveringShift &&
        !this.selectedRoster.otShift
      );
    },
    showExtend() {
      return this.isExtensible && this.canExtendShift;
    },
    showBaseStation() {
      return (
        get(this.selectedRoster, "station.stationName", "-") ===
        get(this.selectedRoster, "onChangeCoveringStation")
      );
    },
    showCoveringStation() {
      return (
        get(this.selectedRoster, "station.stationName", "-") ===
        get(this.selectedRoster, "onChangeBaseStation")
      );
    },
    showOTSection() {
      return (this.showExtend && !this.selectedRoster.otShift) ||
          (this.showPlanOT && !this.selectedRoster.otShift) ||
          //(this.selectedRoster.otVolunteer && this.isLoggedInUser) ||
          //(this.selectedRoster.otUnavailable && this.isLoggedInUser) ||
          (this.canMarkUnavailability && this.checkDWSForAvailability && !this.pendingExtensionToCoverInFull) &&
          !this.selectedRoster.pendingReplacement
          //(this.selectedRoster.otVolunteer)
          //(this.selectedRoster.otUnavailable);
    },
    otDurations() {
      return get(this.selectedRoster, 'otDurations', []);
    },
    hasOtDurations() {
      return this.otDurations.length > 0;
    }
  },
  methods: {
    displayUtcDate2,
    get,
    displayUtcDate,
    displayUtcDateTime,
    addCovering() {
      this.error = null;
      this.showAddCoveringModal = true;
    },
    toggleAddCovering(showModal) {
      this.error = null;
      this.showAddCoveringModal = showModal;
    },
    async onConfirmAddCovering() {
      this.isSubmittingAddCovering = true;
      this.error = null;
      const resp = await coveringShift({
        officerId: this.selectedRoster.officerId,
        planDate: parseDateTime(this.selectedRoster.planDate),
        originalStationId: this.selectedRoster.station.stationId,
        coveringStationId: this.formData.station.value,
      });
      if (resp && resp.success) {
        this.toggleAddCovering(false);
        this.$router.go(this.$router.currentRoute);
      } else {
        this.error = resp.payload;
        this.$scrollTop();
      }
      this.isSubmittingAddCovering = false;
    },
    cancelAvailability() {
      this.error = null;
      this.cancelUnavailabilityConfirmation = true;
    },
    toggleCancelAvailability(showModal) {
      this.error = null;
      this.cancelUnavailabilityConfirmation = showModal;
    },
    async onConfirmCancelUnavailability() {
      this.isSubmittingCancelUnavailability = true;
      const resp = await cancelAvailability({
        rosterId: get(this.selectedRoster, "rosterId"),
      });
      if (resp && resp.success) {
        this.toggleCancelAvailability(false);
        this.$router.go(this.$router.currentRoute);
      } else {
        this.error = resp.payload;
        this.$scrollTop();
      }
      this.isSubmittingCancelUnavailability = false;
    },
    onCancelShiftType(type) {
      if (type == "BL") {
        this.cancellationShiftType.type = "BL";
        this.cancellationShiftType.message =
          "Are you sure you would like to Cancel Indicated Leave?";
      } else if (type == "OTU") {
        this.cancellationShiftType.type = "OTU";
        this.cancellationShiftType.message =
          "Are you sure you would like to Cancel OT Unavailibility?";
      } else if (type == "OTV") {
        this.cancellationShiftType.type = "OTV";
        this.cancellationShiftType.message =
          "Are you sure you would like to Cancel OT Volunteering?";
      }
      this.showConfirmCancelShiftChange = true;
    },
    async cancelShiftTypeConfirm(type) {
      this.isSubmittingCancelShiftChange = true;
      let result = {};
      if (type == "BL") {
        result = await removeBlockedLeave({
          blockedLeaveId: get(this.selectedRoster, "blockedLeaveId"),
        });
      } else if (type == "OTU") {
        result = await removeOTUnavailability({
          otUnavailableId: get(this.selectedRoster, "otUnavailableId"),
        });
      } else if (type == "OTV") {
        result = await removeOTVolunteer({
          otVolunteerId: get(this.selectedRoster, "otVolunteerId"),
        });
      }
      if (result.success) {
        this.toggleCancelShiftChangeModal(false);
        this.$router.go(this.$router.currentRoute);
      } else this.error = result.payload;
      this.isSubmittingCancelShiftChange = false;
    },
    toggleCancelShiftChangeModal(showModal) {
      this.showConfirmCancelShiftChange = showModal;
    },
    mutualExchange() {
      this.$router.push({
        name: "Mutual Exchange",
        query: {
          station: this.selectedRoster.station.stationId,
          officer: this.selectedRoster.officerId,
          date: this.selectedRoster.planDate,
        },
      });
    },
    editRoster() {
      this.$router.push({
        name: "Edit Roster",
        params: { rosterId: this.selectedRoster.rosterId },
      });
    },
    replaceStaff() {
      this.$router.push({
        name: "Staff Replacement",
        params: { rosterId: this.selectedRoster.rosterId },
      });
    },
    markUnavailable() {
      this.$router.push({
        name: "Staff Unavailability",
        params: { rosterId: this.selectedRoster.rosterId },
      });
    },
    markOTUnavailable() {
      this.$router.push({
        name: "Mark_OT_Unavail",
        params: { rosterId: this.selectedRoster.rosterId },
        query: { date: this.selectedRoster.planDate },
      });
    },
    volunteerOT() {
      this.$router.push({
        name: "OT_Volunteers",
        params: { rosterId: this.selectedRoster.rosterId },
        query: { date: this.selectedRoster.planDate },
      });
    },
    planOT() {
      this.$router.push({
        name: "Planned OT",
        params: { rosterId: this.selectedRoster.rosterId },
      });
    },
    toggleCancelCoveringModal(showModal) {
      this.showConfirmCancelCovering = showModal;
    },

    formatOtDuration({ startTime, endTime }) {
      return moment(startTime).format(
            "HH:mm"
          ) +
          " - " +
          moment(endTime).format(
            "HH:mm"
          );
    },
    formatBreakDuration(otBreakHours) {
      return (otBreakHours == '00:00:00' ?
        `no break` :
        `${moment(otBreakHours, 'HH:mm:ss').format("HH:mm")}`);
    },
    async cancelCovering() {
      this.isSubmittingCancelCovering = true;
      this.error = null;
      const resp = await cancelCoveringShift(this.selectedRoster.rosterId);
      if (resp && resp.success) {
        this.toggleCancelCoveringModal(false);
        this.$router.go(this.$router.currentRoute);
      } else {
        this.error = resp.payload;
        this.$scrollTop();
      }
      this.isSubmittingCancelCovering = false;
    },
  },
};
</script>
