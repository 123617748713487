<template>
  <div class="space-y-4">
    <not-base-station-alert v-if="showNotBaseStationAlert && filters.station && !error"
      :station-id="filters.station && filters.station.value"
      :station-name="filters.station && filters.station.label"
    />
    <div class="space-y-10">
      <asom-card>
        <div class="grid sm:grid-cols-1 md:grid-cols-2 md:gap-x-6 gap-4">
          <asom-form-field label="Date"
            required
            :state="inputStates('filters.dateRange')"
            error="Please select a valid date range"
          >
            <asom-input-date-range
              v-model="filters.dateRange"
              datepicker-placement="bottom"
              :state="inputStates('filters.dateRange')"
            />
          </asom-form-field>
          <asom-form-field label="Line" required
            :state="inputStates('filters.line')"
            error="Please select a MRT line"
          >
            <asom-input-select
              :disabled="!canSelectLine"
              v-model="filters.line"
              :options="lineOptions"
              :state="inputStates('filters.line')"
            />
          </asom-form-field>
          <asom-form-field label="Station" required
            :state="inputStates('filters.station')"
            error="Please select a MRT station"
          >
            <asom-input-select
              v-model="filters.station"
              :options="stationOptions"
            :state="inputStates('filters.station')"
            />
          </asom-form-field>
          <asom-form-field label="Officer">
            <asom-multi-select
              :objectModelValue="false"
              v-model="filters.officerIds"
              :options="officerOptions"
              placeholder="Select officer(s)"
            />
          </asom-form-field>
        </div>
        <template #footer>
          <asom-button
            text="Reset Filters"
            variant="secondary"
            @click="resetFilters"
          />
          <asom-button text="Apply" @click="onApplyFilter" />
        </template>
      </asom-card>
      <asom-alert
        v-if="!isLoading && error"
        variant="error"
        :error-message="error"
      />
      <div class="w-full text-center" v-if="isLoading">
        <asom-icon icon="spinner" class="animate-spin" />
      </div>
      <roster-grid
        v-if="!isLoading && !error"
        :rosters="rosters"
        @cell-click="onRosterSelected"
        :userId="userId"
      >
      </roster-grid>
    </div>
    <roster-slide-menu
      v-model="showRightMenu"
      :selected-roster="selectedRoster"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { required } from "@vuelidate/validators";
import inputStates from "@/mixins/inputStates";
import { useVuelidate } from "@vuelidate/core";
import get from "lodash.get";
import moment from 'moment';
import { listUsers } from "@/services/user.service";
import { getRosterList } from "@/services/manpower.service";
import { parseDateTime } from "@/helpers/dateTimeHelpers";
import RosterGrid from "./RosterGrid";
import RosterSlideMenu from "./RosterSlideMenu";
import NotBaseStationAlert from "./NotBaseStationAlert.vue";
import router from "@/router/index"
import { isMobileSizeDisplay } from '@/helpers/browserHelpers';
import { setStorage, getStorage } from "@/helpers/sessionStorage";

export default {
  name: "Rosters",
  mixins: [inputStates],
  setup: () => ({ v$: useVuelidate() }),
  components: {
    RosterGrid,
    RosterSlideMenu,
    NotBaseStationAlert
},
  data() {
    return {
      isInt: false,
      filters: {
        dateRange: {
          start: moment()
            .toDate(),
          end: moment()
            .add(1, "months")
            .toDate(),
        },
        station: null,
        // this.$store.getters['auth/userStationOption'],
        line: this.$store.getters['auth/userLineOption'],
        officerIds: [],
      },
      officerOptions: [],
      rosters: [],
      selectedRoster: null,
      showRightMenu: false,
      isLoading: false,
      error: null,

      searchVal: null,
      filterEcho: 0,
    };
  },
  validations() {
    return {
      filters: {
        dateRange: {
          start: { required },
          end: { required },
        },
        station: { required },
        line: { required },
      }
    };
  },
  watch: {
    "filters.line": function(newValue) {
      if(!this.filterEcho || this.filterEcho > 1){
        if (newValue) {
          this.filters.station = null;
          this.filters.officerIds = [];
        }
      }
      this.filterEcho = this.filterEcho + 1;
      this.queryOfficerList();
    },
    "filters.station": function() {
      this.queryOfficerList();
    },
  },
  mounted() {
    this.searchVal = getStorage("manpower");
    if(this.searchVal) {
      this.filterEcho = 1;

      // UTC时间转换成对应的本地时间
      if(this.searchVal.dateRange && this.searchVal.dateRange.start) this.searchVal.dateRange.start = moment.utc(this.searchVal.dateRange.start).toDate();
      if(this.searchVal.dateRange && this.searchVal.dateRange.end) this.searchVal.dateRange.end = moment.utc(this.searchVal.dateRange.end).toDate();
      this.filters = this.searchVal;
    }else {
      if (Object.keys(this.$route.query).length !== 0) {
        const momentStart = moment(this.$route.query.start, ["DD/MM/YYYY"]);
        const momentEnd = moment(this.$route.query.end, ["DD/MM/YYYY"]);
        if (momentStart.isValid && momentEnd.isValid) {
          this.filters.dateRange = {
            start: momentStart.toDate(),
            end: momentEnd.toDate()
          }
        }
      }
      else {
        this.$router.push({
          path: this.$route.path,
          query: {
            start: moment(this.filters.dateRange.start, ["DD/MM/YYYY"]).format("DD/MM/YYYY"),
            end: moment(this.filters.dateRange.end, ["DD/MM/YYYY"]).format("DD/MM/YYYY"),
          }
        });
      }
    }

    this.$nextTick(() => {
      this.isInt = true;
      this.filters.station = this.$store.getters["smrtResource/stationOptionsByLineId"](
        this.userLineId, this.userStationId
      )
      this.checkDisplay();
      this.queryOfficerList();
      this.loadData();
      this.checkAuthorized();
      this.getCurrentRoster();
    })
  },
  computed: {
    ...mapGetters({
      userLineId: "auth/userLineId",
      userStationId: "auth/userStationId",
      lineOptions: "smrtResource/lineOptions",
      userId: "auth/userId",
      isBelongToCCL: "auth/isBelongToCCL",
      isOCC: "auth/isOCC",
      canMarkUnavailability: "auth/canMarkUnavailability",
      canSelectLine: 'auth/canSelectLine',
      showNotBaseStationAlert: 'auth/showNotBaseStationAlert',
    }),
    stationOptions() {
      return this.$store.getters["smrtResource/stationOptionsByLineId"](
        get(this.filters.line, "value")
      );
    },
    queryParams() {
      let params = {};
      if (
        this.filters.dateRange &&
        this.filters.dateRange.start &&
        this.filters.dateRange.end
      ) {
        params["dateFrom"] = parseDateTime(this.filters.dateRange.start);
        params["dateTo"] = parseDateTime(this.filters.dateRange.end);
      }
      if (this.filters.line) {
        params["lineId"] = this.filters.line.value;
      }
      if (this.filters.station) {
        params["stationId"] = this.filters.station.value;
      }
      if (this.filters.officerIds) {
        params["officers"] = this.filters.officerIds;
      }
      return params;
    },
  },
  methods: {
    checkDisplay() {
      if (isMobileSizeDisplay())
        window.alert("This page is not designed to display in mobile's portrait mode. Consider rotating the device to use in landscape mode, or use a bigger device.")
    },
    onApplyFilter() {
      const filterObj = { ...this.filters,
        dateRange: {
          start: this.queryParams.dateFrom,
          end: this.queryParams.dateTo,
        }
      }
      setStorage("manpower", filterObj);
      this.v$.$reset();
      this.v$.$touch();
      if (!this.v$.$invalid) {
        this.loadData();
      }
    },
    async loadData() {
      this.isLoading = true;
      const momentStart = moment(this.filters.dateRange.start, ["DD/MM/YYYY"]);
      const momentEnd = moment(this.filters.dateRange.end, ["DD/MM/YYYY"]);
      if (momentStart.isValid && momentEnd.isValid &&
        (this.$route.query.start !== momentStart.format("DD/MM/YYYY") || this.$route.query.end !== momentEnd.format("DD/MM/YYYY")))
        {
        this.$router.push({
          path: this.$route.path,
          query: {
            start: momentStart.format("DD/MM/YYYY"),
            end: momentEnd.format("DD/MM/YYYY"),
          }
        });
      }
      else {
        let resp = await getRosterList({
          ...this.queryParams,
        });
        let result = [];
        result = [...result, ...get(resp, "payload.list", [])];
        result.forEach( rst => {
          rst.officer = this.ConcatPersonNo(rst.officer, rst.personNo)
        });
        if (resp.success) {
          this.rosters = result;
          this.error = null;
        } else {
          this.error = resp.payload;
          this.$scrollTop();
        }
      }
      this.isLoading = false;
    },
    ConcatPersonNo(name, personNo){
      while(personNo.length > 1 && personNo[0] == '0')
      {
        personNo = personNo.substring(1);
      }
      return name + "_" + personNo;
    },
    resetFilters() {
      this.filters.dateRange = null;
      this.filters.station = null;
      this.filters.officerIds = [];
    },
    async queryOfficerList() {
      if(!this.isInt) return;
      const result = await listUsers({
        skip: 0,
        take: 100,
        lineId: this.filters.line ? this.filters.line.value : null,
        stationId: this.filters.station ? this.filters.station.value : null,
      });

      if (result.success && Array.isArray(get(result, "payload.list"))) {
        this.error = null;
        this.officerOptions = get(result, "payload.list").map(
          ({ id, name, personNo }) => ({
            label: `${name} (${personNo})`,
            value: id,
          })
        );
      } else {
        this.error = result.payload;
        this.officerOptions = [];
        this.$scrollTop();
      }
    },
    onRosterSelected(roster) {
      if (roster) {
        this.selectedRoster = roster;
        this.showRightMenu = true;
      }
    },
    checkAuthorized() {
      if (this.isOCC && !this.isBelongToCCL) {
        router.push({ name: '403 Forbidden' })
      }
    },
    getCurrentRoster(){
      this.$store.dispatch("currentRoster/getCurrentRoster")
    }
  },
};
</script>

<style></style>
